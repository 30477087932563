
import axios from 'axios'
import { isEmpty } from 'element-plus/es/utils'
import { defineComponent } from 'vue'
import Pagination from '@/views/video/pagination.vue'
import VideoModal from '@/views/video/videoModal.vue'

interface Video {
  id: number
  title: string
  mid: string
  view: number
  created: string
  category_id: number
  video_category: {
    title: string
  } | null
  video_source: {
    title: string
  } | null
  iframe_src: string
  content: string
}

export default defineComponent({
  components: {
    Pagination,
    VideoModal,
  },
  props: ['user'],
  data() {
    return {
      sliders: null,
      videos: null as Video[] | null,
      videoCategories: [] as { id: number; title: string }[],
      videoSources: [] as { id: number; title: string; mid: string }[],
      isvideos: true,
      loading: true,
      currentPage: 1,
      totalPages: 0,
      selectedVideo: null as Video | null,
      showModal: false,
      currentVideoIndex: 0,
    }
  },
  created() {
    this.fetchVideoList()
    this.fetchVideoCategoryList()
    this.fetchVideoSourceList()
  },
  methods: {
    async fetchVideoList(): Promise<void> {
      let catId = 0
      if (this.$route.query.catId !== undefined) {
        catId = parseInt(this.$route.query.catId as string)
      }
      const response = await axios.post('/api/web/video/list', {
        page: this.currentPage,
        catId,
      })
      const { data, total, per_page } = response.data.data
      this.videos = data
      this.totalPages = Math.ceil(total / per_page)
      this.isvideos = !isEmpty(data)
      this.loading = false
    },
    async fetchVideoCategoryList(): Promise<void> {
      const response = await axios.post('/api/web/video/category/list')
      this.videoCategories = response.data.data.data
      this.loading = false
    },
    async fetchVideoSourceList(): Promise<void> {
      const response = await axios.post('/api/web/video/source/list')
      const items = response.data.data.data
      this.videoSources = items
      this.loading = false
    },
    setPage(page: number): void {
      this.currentPage = page
      this.fetchVideoList()
    },
    showVideoModal(video: Video, index: number): void {
      this.selectedVideo = video
      this.currentVideoIndex = index
      this.showModal = true
    },
    closeModal(): void {
      this.showModal = false
      this.selectedVideo = null
    },
    changeVideo(direction: 'next' | 'prev'): void {
      if (direction === 'next') {
        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videos!.length
      } else {
        this.currentVideoIndex = (this.currentVideoIndex - 1 + this.videos!.length) % this.videos!.length
      }
      this.selectedVideo = this.videos![this.currentVideoIndex]
    },
  },
})
