
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        selectedVideo: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
        currentVideoIndex: {
            type: Number,
            required: true,
        },
    },
    emits: ['close', 'changeVideo'],
})
