<template>
    <div class="row justify-content-center">
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="setPage(1)">首</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="currentPage > 1 && setPage(currentPage - 1)">《</a>
          </li>
          <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="setPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="currentPage < totalPages && setPage(currentPage + 1)"
              >》</a
            >
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="setPage(totalPages)">尾</a>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
    },
    computed: {
      visiblePages() {
        // 计算显示的页码
        const visiblePages = []
        const totalPages = this.totalPages
        const currentPage = this.currentPage
        const maxPages = 5 // 最多显示的页码数量
  
        let startPage = Math.max(currentPage - Math.floor(maxPages / 2), 1)
        let endPage = startPage + maxPages - 1
  
        if (endPage > totalPages) {
          endPage = totalPages
          startPage = Math.max(endPage - maxPages + 1, 1)
        }
  
        for (let page = startPage; page <= endPage; page++) {
          visiblePages.push(page)
        }
  
        return visiblePages
      },
    },
    methods: {
      setPage(page) {
        this.$emit('update:currentPage', page)
      },
    },
  }
  </script>